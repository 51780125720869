import React, { useEffect, useMemo, useState } from 'react'
import { some, toNumber } from 'lodash-es'
import { ContactLensSelection } from '../ContactLensSelection'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import ProductCtaContainer from '../ProductCtaContainer'
import { ProductDetailsList } from '../ProductDetailsList'
import { ProductNameCLSection } from '../ProductNameCLSection'
import { ProductRightColumnProps, SupplyData } from '@typesApp/product'
import SizeSwitcher from '../SizeSwitcher'
import { getClPackSize } from '../../../../utils/productAttributes'
import { useAnnualSupply } from '../../hooks/useAnnualSupply'
import { useTranslation } from 'next-i18next'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'
import { PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { getProductAlgoliaPrice } from '../../../../foundation/algolia/algoliaPrice'
import { getFormattedPlpProduct } from '@features/plp/utils'
import { TrustPilotMiniWidget } from '@components/TrustPilotReviews/TrustPilotWidget'
import useBreakpoints from '@hooks/useBreakpoints'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import clsx from 'clsx'
import styles from './styles/PdpRight.module.scss'

const ASSOCIATION_TYPE = 'CL_Pack_Sizes'

export const handleDiscountedBoxQuantities = (supplyData: SupplyData[], totalBoxes: number) => {
  const fixedSupplyQuantities = supplyData.map(y => y.quantity)
  const isMultipleOfQuantity = fixedSupplyQuantities.find(u => totalBoxes / +u! >= 1)

  const leftoverBoxes = (isMultipleOfQuantity && totalBoxes % +isMultipleOfQuantity) || 0
  const originalBoxPrice =
    supplyData && supplyData?.find(q => q.quantity === isMultipleOfQuantity || q)?.originalBoxPrice
  const discountedBoxPrice = supplyData?.find(d => d.quantity === isMultipleOfQuantity)?.discountedBoxPrice

  const combinedPricePerBox = isMultipleOfQuantity
    ? leftoverBoxes === 0
      ? (+discountedBoxPrice! * totalBoxes) / totalBoxes
      : (+originalBoxPrice! * (leftoverBoxes || 1) + +discountedBoxPrice! * +(totalBoxes - leftoverBoxes)) / totalBoxes
    : +originalBoxPrice!

  return combinedPricePerBox
}

export const ContactLensesPdpRightColumn = (props: ProductRightColumnProps) => {
  const {
    pdpData,
    partNumber,
    isLoading,
    currentProduct,
    soldOutStatus,
    productInCart,
    addToCartButtonFail,
    ctaRef,
    stickyBarCtaRef,
    isRoxable,
    isEditing,
    customerSegments,
    productQuantity,
    setProductQuantity,
    adjustments,
    setAdjustments,
    placements,
  } = props
  const [totalBoxes, setTotalBoxes] = useState<number>(toNumber(productQuantity))
  const supplyData = useAnnualSupply(currentProduct)
  const displayable = currentProduct.displayable
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()

  const getAssociatedMerchandising = cp => {
    return some(cp?.merchandisingAssociations, ma => ma.associationType === ASSOCIATION_TYPE)
  }

  const hasAssociatedMerchandising = useMemo(() => getAssociatedMerchandising(currentProduct), [currentProduct])

  const getSizeSwitcherOptions = cp => {
    const massocArray = cp?.merchandisingAssociations.filter(ma => {
      return ma?.associationType === ASSOCIATION_TYPE
    })
    const currentSize = cp && getClPackSize(cp)

    const filteredMassocArray = massocArray
      .map(ma => {
        const formattedProduct = getFormattedPlpProduct(ma)

        const size = getClPackSize(formattedProduct?.clusters?.[0])
        return { size, label: t('pack'), href: ma?.seo?.href }
      })
      .filter(ma => ma.size !== currentSize)

    if (filteredMassocArray?.length) {
      return [filteredMassocArray[0]]
    }
    return []
  }

  const options = useMemo(() => getSizeSwitcherOptions(currentProduct) || [], [currentProduct])

  const currentSize = currentProduct && getClPackSize(currentProduct)

  useEffect(() => {
    setProductQuantity([`${totalBoxes}`])
  }, [totalBoxes, setProductQuantity])

  const algoliaPrices = getProductAlgoliaPrice(customerSegments, currentProduct!)

  return (
    <>
      {currentProduct && <ProductNameCLSection currentProduct={currentProduct} />}
      {isDesktop && currentProduct && <TrustPilotMiniWidget sku={partNumber} />}
      {hasAssociatedMerchandising && !!options?.length && !!currentSize ? (
        <SizeSwitcher options={options} currentSize={currentSize} />
      ) : null}

      <div className={clsx(styles.contactLensesSeparator)} />
      {currentProduct && displayable && (
        <ContactLensSelection
          ctaRef={ctaRef}
          stickyBarCtaRef={stickyBarCtaRef}
          currentProduct={currentProduct}
          pdpData={pdpData}
          pdpdataLoading={isLoading}
          soldOutStatus={soldOutStatus}
          setTotalBoxes={setTotalBoxes}
          supplyData={supplyData}
          isLoading={isLoading}
          isEditingContactLens={isEditing}
          adjustments={adjustments}
          setAdjustments={setAdjustments}
        />
      )}
      {currentProduct && displayable && (
        <ProductCtaContainer
          productInCart={productInCart}
          isLoading={isLoading}
          soldOutStatus={soldOutStatus}
          currentProduct={currentProduct}
          error={addToCartButtonFail}
          ctaRef={ctaRef}
          roxable={isRoxable}
          isEditing={isEditing}
          productQuantity={productQuantity}
          algoliaPrices={algoliaPrices}
        />
      )}
      <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.CONTACT_LENSES} />
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.attributes || []}
        product={currentProduct!}
        soldOutStatus={soldOutStatus}
      />

      <OmniChannelCarousel placements={placements ?? []} />

      {currentProduct && (
        <ProductDetailsList type={soldOutStatus} isLoading={isLoading} currentProduct={currentProduct} />
      )}
    </>
  )
}
