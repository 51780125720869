import { useEffect } from 'react'

export const useTrustPilotScript = (noScript: boolean) => {
  useEffect(() => {
    if (!noScript) {
      const script = document.createElement('script')
      script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }
    return () => {}
  }, [noScript])
}
