import React, { Fragment, useEffect } from 'react'
import { getFacetLabel } from '@utils/productAttributesAlgolia'
import FilterIcons from './FilterIcons'
import { Pill } from '@components/UI/Pill'
import { useRefinementList } from 'react-instantsearch-hooks-web'
import { useTranslation } from 'next-i18next'
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList'
import type { SearchResults } from 'algoliasearch-helper'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface IFacetFilterPill {
  facetValues: string[]
  facetName: string
  facet: string
  discountItems?: SearchResults.HierarchicalFacet[]
  onFacetChange: (selection: string, label: string, parent: string, setFacet: (string) => void) => void
  searchTerm?: string
}

const FacetFilterPill: React.FC<IFacetFilterPill> = ({
  facetValues,
  facetName,
  facet,
  discountItems,
  onFacetChange,
  searchTerm,
}) => {
  const { t } = useTranslation()
  const DISCOUNT_FACET = 'DISCOUNT'
  const { items, refine, searchForItems } = useRefinementList({
    attribute: facet,
    operator: 'or',
    sortBy: ['count'],
    limit: facetValues.length,
  })

  useEffect(() => {
    searchForItems(searchTerm || '')
  }, [searchForItems, searchTerm])

  return (
    <>
      {facetValues.map(facetValue => {
        if (facetValue === '0' || Number(facetValue) === 0) return null
        const item =
          facetName === DISCOUNT_FACET
            ? discountItems?.find(item => item.escapedValue === facetValue)
            : items.find(item => item.value === facetValue)
        let isRefined, label
        if (item) {
          isRefined = item.isRefined
          if (Object.hasOwn(item, label)) {
            label = (item as RefinementListItem)?.label
          }
        }
        if (facetName === DISCOUNT_FACET) {
          label = t('ProductGrid.Labels.discount', { percentage: +facetValue })
        }
        const isDisabled = !item

        const parsedFacetValue = facetValue.includes('|')
          ? facetValue.split('|')[1].toLowerCase()
          : facetValue.toLowerCase()
        const startIcon = <FilterIcons teaserIcon={parsedFacetValue} />
        const endIcon = isRefined ? <SVGIcon library="close" name="close-circle" /> : null
        const variant = isRefined ? 'tertiary' : 'secondary'
        return (
          <Fragment key={facetValue}>
            <Pill
              id={facetValue}
              key={facetValue}
              startIcon={startIcon}
              endIcon={endIcon}
              variant={variant}
              disabled={isDisabled}
              labelText={label || getFacetLabel(facetValue)}
              onClick={() => {
                onFacetChange(facetValue, label, facet, refine)
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}

export default FacetFilterPill
