import { useEffect, useMemo, useState } from 'react'
import {
  ContactLensesData,
  EyeClFieldConfig,
  EyeContanctLensOption,
  IProduct,
  EyeClFieldConfigMap,
  EyeContactLensAttribute,
  EyeContactLensOverrideMap,
  EyeContactLensAttributeMapping,
} from '@typesApp/product'
import {
  getClAdd,
  getClAddition,
  getClAdditionRange,
  getClAxis,
  getClAxisRange,
  getClColorRange,
  getClCylinderRange,
  getClDominance,
  getClLensRange,
  getClSpherePower,
  getProductAttribute,
} from '../../../../utils/productAttributes'
import { uniqBy, sum, uniq, toNumber } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import Log from '../../../../services/Log'
import { TFunction } from 'i18next'
import { generateQuantityOptions } from '../../../../utils/order'
import { CL_EYE_LEFT, CL_EYE_RIGHT } from '../../../../constants/product'
import { CONTACT_LENS_DEFAULT_QUANTITY, CONTACT_LENS_MAX_QUANTITY, CONTACT_LENS_MIN_QUANTITY } from '@constants/common'
import { isMtoProduct } from '@utils/product'

/**
 * @param { IProduct } product current selected product object
 */

export const attributesList = [
  'CL_SPHERE_POWER',
  'CL_LENS_RANGE',
  'CL_AXIS',
  'CL_COLOR_RANGE',
  'CL_ADDITION',
  'CL_CYL',
  'CL_DOMINANCE',
]

const StringToNumberValuesMap: Record<string, number> = {
  low: 555,
  medium: 556,
  high: 557,
  '-': 777,
}

const NumberToStringsValuesMap: Record<number, string> = {
  555: 'low',
  556: 'medium',
  557: 'high',
  777: '-',
}

const isNullableOption = (value: number): boolean => {
  return value === 777
}

const formatOptionValue = (value: number, multifield?: boolean): string => {
  return multifield ? (value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)) : value.toString()
}

const getSortedUniqs = (values: EyeContactLensAttributeMapping[], sign: 'negative' | 'positive') => {
  return sortValues(
    uniqBy(
      values.filter(val => (sign === 'negative' ? val.parsedValue < 0 : val.parsedValue > 0)),
      'parsedValue'
    )
  )
}

const sortValues = (arr: EyeContactLensAttributeMapping[]) => {
  return arr.sort(function (a, b) {
    return Math.abs(a.parsedValue) - Math.abs(b.parsedValue)
  })
}

const createOrderedAndParsedValues = (
  negativeValues: EyeContactLensAttributeMapping[],
  positiveValues: EyeContactLensAttributeMapping[]
) => {
  const orderedAndParsedValues: EyeContactLensAttributeMapping[] = []
  const combinedValues = sortValues(negativeValues.concat(positiveValues))
  const uniqueValues = uniq(combinedValues.map(i => Math.abs(i.parsedValue)))

  uniqueValues.map(i => {
    const negativeValue = negativeValues.find(neg => Math.abs(neg.parsedValue) === i) ?? {
      parsedValue: 777,
      orginalValue: '777',
    }
    const positiveValue = positiveValues.find(pos => Math.abs(pos.parsedValue) === i) ?? {
      parsedValue: 777,
      orginalValue: '777',
    }

    orderedAndParsedValues.push(negativeValue)
    orderedAndParsedValues.push(positiveValue)
  })

  return orderedAndParsedValues
}

const orderByOddAndEven = (values: EyeContactLensAttributeMapping[]): EyeContactLensAttributeMapping[] => {
  let positiveValues: EyeContactLensAttributeMapping[] = []
  let negativeValues: EyeContactLensAttributeMapping[] = []
  negativeValues = getSortedUniqs(values, 'negative')
  positiveValues = getSortedUniqs(values, 'positive')

  return createOrderedAndParsedValues(negativeValues, positiveValues) || []
}

export const parseNumericProductAttributeValues = (
  attributeValue?: string | string[],
  multifield?: boolean
): EyeContanctLensOption[] => {
  let orderedOptions: EyeContanctLensOption[] = []
  try {
    const parsedOriginalValMapping: EyeContactLensAttributeMapping[] =
      !!attributeValue && attributeValue !== ''
        ? Array.isArray(attributeValue)
          ? attributeValue.map(value =>
              isNaN(parseFloat(value))
                ? {
                    parsedValue: StringToNumberValuesMap[value.toLowerCase()],
                    orginalValue: value,
                  }
                : {
                    parsedValue: parseFloat(value),
                    orginalValue: value,
                  }
            )
          : isNaN(parseFloat(attributeValue || ''))
            ? [
                {
                  parsedValue: StringToNumberValuesMap[attributeValue.toLowerCase()],
                  orginalValue: attributeValue,
                },
              ]
            : [
                {
                  parsedValue: parseFloat(attributeValue),
                  orginalValue: attributeValue,
                },
              ]
        : []

    const orderedAttributes = multifield ? orderByOddAndEven(parsedOriginalValMapping) : parsedOriginalValMapping
    orderedOptions = orderedAttributes.map((val: EyeContactLensAttributeMapping, i) => {
      return {
        text: !!NumberToStringsValuesMap[val.parsedValue]
          ? NumberToStringsValuesMap[val.parsedValue]
          : formatOptionValue(val.parsedValue, multifield),
        value: isNullableOption(val.parsedValue)
          ? null
          : !!NumberToStringsValuesMap[val.orginalValue]
            ? NumberToStringsValuesMap[val.orginalValue]
            : val.orginalValue.toString(),
        index: i,
      }
    })
  } catch (e) {
    Log.error('error parsing product attributes values: ' + e)
  }

  return multifield ? orderedOptions : uniqBy(orderedOptions, 'value')
}

export const parseStringProductAttributeValues = (attributeValue?: string | string[]): EyeContanctLensOption[] => {
  const attributeValues = !!attributeValue ? (Array.isArray(attributeValue) ? attributeValue : [attributeValue]) : []
  return attributeValues.map((val, index) => {
    return {
      text: val,
      value: val,
      index,
    }
  })
}

const generateCylinderOptions = (product: IProduct) => {
  const rangeArray = [
    toNumber(getProductAttribute(product, 'CL_CYL_MIN')),
    toNumber(getProductAttribute(product, 'CL_CYL_MAX')),
  ]

  const [min, max] = rangeArray.sort((a, b) => a - b)

  const steps = toNumber(getProductAttribute(product, 'CL_CYL_STEPS'))
  const attributeValues: number[] = []

  for (let i = min; i <= max; i += steps) {
    attributeValues.push(i)
  }

  return attributeValues
}

const getDefaultOption = (options: EyeContanctLensOption[]): string => {
  return !isSelect(options)
    ? uniqBy(options, 'value')
        ?.find(option => option.value !== null)
        ?.value?.toString() || ''
    : ''
}
const getDefaultOverridedValue = (
  values: EyeContactLensOverrideMap,
  attrName: EyeContactLensAttribute,
  eye: string
): string | null => {
  try {
    const found =
      values[eye]?.find(value => {
        return value.attribute === attrName
      })?.value || ''
    return found
  } catch (e) {
    return null
  }
}

const isSelect = (options: EyeContanctLensOption[]): boolean => {
  return options.length > 2
}

const isFieldVisible = (options: EyeContanctLensOption[]): boolean => {
  return options.length > 1
}

const getDominanceFieldOptionsByProductAttribute = (
  t: TFunction,
  attributeValue?: boolean | string | string[]
): EyeContanctLensOption[] => {
  const arrValues = Array.isArray(attributeValue) ? Array.from(new Set(attributeValue)) : attributeValue
  let options = [
    {
      text: '-',
      value: '',
      index: 0,
    },
  ]
  if (Array.isArray(arrValues)) {
    arrValues.map((value, index) => {
      options.push({
        text: t(`ContactLenses.Labels.Dominance.${value}`),
        value: value,
        index: index + 1,
      })
    })
  } else if (typeof arrValues === 'string') {
    options.push({
      text: t(`ContactLenses.Labels.Dominance.${arrValues}`),
      value: arrValues,
      index: 1,
    })
  } else {
    options = []
  }

  return options
}

const getFieldOptionsByProductAttribute = (
  attributeValue?: string | string[],
  multifield?: boolean,
  isStringAttribute?: boolean
): EyeContanctLensOption[] => {
  let options: EyeContanctLensOption[] = [
    {
      text: '-',
      value: null,
      index: 0,
    },
  ]
  if (!isStringAttribute && multifield) {
    options.push({
      text: '+0.00',
      value: '+0.00',
      index: 1,
    })
  }
  try {
    options = options.concat(
      isStringAttribute
        ? parseStringProductAttributeValues(attributeValue)
        : parseNumericProductAttributeValues(attributeValue, multifield)
    )
  } catch (e) {
    Log.error('contact lenses options parsing error: ' + e)
  }
  return options
}

const getMultiPleLensAttrValues = (
  attrName: string,
  p: IProduct
): Record<'x_spherePower' | 'x_baseCurve' | 'x_diameter', string[]> => {
  const attributeValuesaArray = p.productAttributes[attrName]

  const lensAttributeNameValuesMap: Record<'x_spherePower' | 'x_baseCurve' | 'x_diameter', string[]> = {
    x_spherePower: [],
    x_baseCurve: [],
    x_diameter: [],
  }

  try {
    Array.isArray(attributeValuesaArray)
      ? attributeValuesaArray?.map(node => {
          lensAttributeNameValuesMap.x_baseCurve.push(node.split(' ')[0])
          lensAttributeNameValuesMap.x_spherePower.push(node.split(' ')[1])
          lensAttributeNameValuesMap.x_diameter.push(node.split(' ')[2])
        })
      : null
  } catch (e) {}
  return lensAttributeNameValuesMap || null
}

export const formatDataForCart = (contactLensesData: ContactLensesData): ContactLensesData => {
  const parsedObj = {}
  try {
    Object.keys(contactLensesData).map(eye => {
      const eyeData = { ...contactLensesData[eye] }
      Object.keys(eyeData).map(fieldId => {
        const fieldValue = eyeData[fieldId]
        const reversed = !!NumberToStringsValuesMap[parseFloat(fieldValue)]
          ? NumberToStringsValuesMap[parseFloat(fieldValue)]
          : fieldValue
        eyeData[fieldId] = reversed
      })
      if (eyeData.fieldsStatus?.enabled) {
        delete eyeData.fieldsStatus
        parsedObj[eye] = eyeData
      }
    })
  } catch (e) {
    Log.error('contact lenses reverse mapping error: ' + e)
  }

  return parsedObj
}
const getLensConfiguration = (t: TFunction, product: IProduct, defaultValueOverrides?: EyeContactLensOverrideMap) => {
  const config: EyeClFieldConfigMap = {
    left: [],
    right: [],
  }
  const eyesMap = [CL_EYE_LEFT, CL_EYE_RIGHT]
  const isMto = isMtoProduct(product)

  try {
    eyesMap.map(eye => {
      attributesList.map(attr => {
        switch (attr) {
          case 'CL_LENS_RANGE':
            const multipleAttrNameValueMap = getMultiPleLensAttrValues(attr, product)
            const bcOptions: EyeContanctLensOption[] = !!multipleAttrNameValueMap.x_baseCurve
              ? getFieldOptionsByProductAttribute(multipleAttrNameValueMap.x_baseCurve)
              : []
            const diaOptions: EyeContanctLensOption[] = !!multipleAttrNameValueMap.x_diameter
              ? getFieldOptionsByProductAttribute(multipleAttrNameValueMap.x_diameter)
              : []
            const groupedConfigs: EyeClFieldConfig[] = [
              {
                id: 'x_baseCurve',
                label: t('ContactLenses.Labels.BaseCurve'),
                required: isFieldVisible(bcOptions),
                select: isSelect(bcOptions),
                defaultValue: defaultValueOverrides
                  ? getDefaultOverridedValue(defaultValueOverrides, 'x_baseCurve', eye) || getDefaultOption(bcOptions)
                  : getDefaultOption(bcOptions),
                visible: isFieldVisible(bcOptions),
                active: !!getClLensRange(product),
                options: bcOptions,
              },
              {
                id: 'x_diameter',
                label: t('ContactLenses.Labels.Diameter'),
                required: isFieldVisible(diaOptions),
                select: isSelect(diaOptions),
                defaultValue: defaultValueOverrides
                  ? getDefaultOverridedValue(defaultValueOverrides, 'x_diameter', eye) || getDefaultOption(diaOptions)
                  : getDefaultOption(diaOptions),
                visible: isFieldVisible(diaOptions),
                active: !!getClLensRange(product),
                options: diaOptions,
              },
            ]
            config[eye].push(...groupedConfigs)

            break
          case 'CL_SPHERE_POWER':
            const spherePowerOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              getClSpherePower(product),
              true,
              false
            ).map(option => {
              if (!option.value) return option

              let num = parseFloat(option.value)
              if (isNaN(num)) return option
              return {
                ...option,
                value: (num >= 0 ? '+' : '') + num.toFixed(2),
                text: (num >= 0 ? '+' : '') + num.toFixed(2),
              }
            })

            const spherePowerConfig: EyeClFieldConfig = {
              id: 'x_spherePower',
              label: t('ContactLenses.Labels.PowerSphere'),
              required: isFieldVisible(spherePowerOptions),
              select: isSelect(spherePowerOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_spherePower', eye) ||
                  getDefaultOption(spherePowerOptions)
                : getDefaultOption(spherePowerOptions),
              visible: isFieldVisible(spherePowerOptions),
              active: !!getClSpherePower(product),
              multifield: true,
              options: spherePowerOptions,
            }

            config[eye].push(spherePowerConfig)
            break
          case 'CL_AXIS_RANGE':
          case 'CL_AXIS':
            const clAxisRange = isMto ? getClAxis(product) : getClAxisRange(product)

            const axisRangeOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              clAxisRange,
              false,
              false
            )
              .filter(option => option.value !== null && option.value !== undefined)
              .map(option => {
                if (!option.value) return option
                if (option.value.length === 3) return option

                return {
                  ...option,
                  value: option.value.length === 1 ? '00' + option.value : '0' + option.value,
                  text: option.text,
                }
              })

            const axisConfig: EyeClFieldConfig = {
              id: 'x_axis',
              label: t('ContactLenses.Labels.Axis'),
              required: isFieldVisible(axisRangeOptions),
              select: isSelect(axisRangeOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_axis', eye) || getDefaultOption(axisRangeOptions)
                : getDefaultOption(axisRangeOptions),
              visible: isFieldVisible(axisRangeOptions),
              options: axisRangeOptions,
            }
            config[eye].push(axisConfig)

            break
          case 'CL_COLOR_RANGE':
            const colorOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              getClColorRange(product),
              false,
              true
            )
            const colorConfig: EyeClFieldConfig = {
              id: 'x_color',
              label: t('ContactLenses.Labels.Color'),
              required: isFieldVisible(colorOptions),
              select: isSelect(colorOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_color', eye) || getDefaultOption(colorOptions)
                : getDefaultOption(colorOptions),
              visible: isFieldVisible(colorOptions),
              active: !!getClColorRange(product),
              options: colorOptions,
            }
            config[eye].push(colorConfig)
            break
          case 'CL_DOMINANCE':
            const dominanceOptions: EyeContanctLensOption[] = getDominanceFieldOptionsByProductAttribute(
              t,
              getClDominance(product)
            )
            const dominanceConfig: EyeClFieldConfig = {
              id: 'x_dominance',
              label: t('ContactLenses.Labels.Dominance.title'),
              required: isFieldVisible(dominanceOptions) && isSelect(dominanceOptions),
              select: isSelect(dominanceOptions),
              defaultValue: 'NO',
              visible: isFieldVisible(dominanceOptions) && isSelect(dominanceOptions),
              active: !!getClDominance(product),
              options: dominanceOptions,
            }
            config[eye].push(dominanceConfig)
            break
          case 'CL_ADDITION':
          case 'CL_ADDITION_RANGE':
          case 'CL_ADD':
            const clAdditionRange = getClAddition(product) ?? getClAdd(product) ?? getClAdditionRange(product)

            const additionOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(clAdditionRange).map(
              option => {
                if (!option.value) return option
                if (isNaN(parseFloat(option.value))) return option
                return {
                  ...option,
                  value: '+' + parseFloat(option.value).toFixed(2),
                  text: '+' + parseFloat(option.value).toFixed(2),
                }
              }
            )

            const additionConfig: EyeClFieldConfig = {
              id: 'x_addition',
              label: t('ContactLenses.Labels.Addition'),
              required: isFieldVisible(additionOptions),
              select: isSelect(additionOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_addition', eye) ||
                  getDefaultOption(additionOptions)
                : getDefaultOption(additionOptions),
              visible: isFieldVisible(additionOptions),
              active: !!clAdditionRange,
              options: additionOptions,
            }
            config[eye].push(additionConfig)
            break
          case 'CL_CYLINDER_RANGE':
          case 'CL_CYL':
            const clCylinderRange = isMto ? generateCylinderOptions(product) : getClCylinderRange(product)
            const cylinderOptions: EyeContanctLensOption[] = getFieldOptionsByProductAttribute(
              clCylinderRange,
              false,
              false
            )
            const cylinderConfig: EyeClFieldConfig = {
              id: 'x_cylinder',
              label: t('ContactLenses.Labels.Cylinder'),
              required: isFieldVisible(cylinderOptions),
              select: isSelect(cylinderOptions),
              defaultValue: defaultValueOverrides
                ? getDefaultOverridedValue(defaultValueOverrides, 'x_cylinder', eye) ||
                  getDefaultOption(cylinderOptions)
                : getDefaultOption(cylinderOptions),
              visible: isFieldVisible(cylinderOptions),
              active: !!clCylinderRange,
              options: cylinderOptions,
            }
            config[eye].push(cylinderConfig)
            break
          default:
            return config
        }
        return config
      })
      const quantityConfig: EyeClFieldConfig = {
        id: 'quantity',
        label: t('ContactLenses.Labels.Quantity'),
        required: true,
        select: true,
        visible: true,
        defaultValue: defaultValueOverrides
          ? getDefaultOverridedValue(defaultValueOverrides, 'quantity', eye) || CONTACT_LENS_DEFAULT_QUANTITY
          : CONTACT_LENS_DEFAULT_QUANTITY,

        options: generateQuantityOptions(CONTACT_LENS_MAX_QUANTITY, CONTACT_LENS_MIN_QUANTITY),
      }

      config[eye].push(quantityConfig)
    })
    return config
  } catch (e) {
    Log.error('contact lenses config error: ' + e)
  }

  return config
}

const getDefaultValues = (
  configuration: EyeClFieldConfigMap | null
): Record<
  'left' | 'right',
  {
    fieldsDefaultValues: EyeContactLensAttribute[]
    defaultEmptyFields: EyeContactLensAttribute[]
  }
> => {
  const defaultValuesMap: Record<
    'left' | 'right',
    {
      fieldsDefaultValues: EyeContactLensAttribute[]
      defaultEmptyFields: EyeContactLensAttribute[]
    }
  > = {
    left: {
      defaultEmptyFields: [],
      fieldsDefaultValues: [],
    },
    right: { defaultEmptyFields: [], fieldsDefaultValues: [] },
  }
  for (const eye in configuration) {
    const configValues = {}
    const requiredEmptyFields: EyeContactLensAttribute[] = []
    if (!!configuration[eye]) {
      configuration[eye]
        ?.filter(fieldConf => fieldConf.required || fieldConf.visible || fieldConf.active)
        .map(fieldConf => {
          configValues[fieldConf.id] = fieldConf.defaultValue
          if (fieldConf.required && fieldConf.defaultValue === '') {
            requiredEmptyFields.push(fieldConf.id)
          }
        })
      defaultValuesMap[eye] = {
        fieldsDefaultValues: configValues,
        defaultEmptyFields: requiredEmptyFields,
      }
    }
  }
  return defaultValuesMap
}

export const useContactLensSelection = (
  currentProduct?: IProduct,
  pdpData?: IProduct | null,
  defaultValues?: EyeContactLensOverrideMap
) => {
  const { t } = useTranslation()
  const [contactLensFieldsConfig, setContactLensConfig] = useState<EyeClFieldConfigMap | null>({})

  const [fieldsHaveErrors, setFieldsHaveErrors] = useState<boolean>(false)
  const [isColorEmpty, setIsColorEmpty] = useState<boolean>(false)
  const [bothEyesDisabled, setBothEyesDisabled] = useState<boolean>(false)
  const [defaultEmptyFields, setDefaultEmptyFields] = useState<Record<'left' | 'right', EyeContactLensAttribute[]>>({
    left: [],
    right: [],
  })
  const [defaultFieldsValues, setDefaultFieldsValues] = useState<Record<'left' | 'right', EyeContactLensAttribute[]>>({
    left: [],
    right: [],
  })

  const initialContactLensesState: ContactLensesData = {
    left: {
      x_eye: 'LCON',
      fieldsStatus: {
        enabled: true,
        errors: [],
        dirtyFields: [],
        emptyFields: [''],
        valid: false,
      },
    },
    right: {
      x_eye: 'RCON',
      fieldsStatus: {
        enabled: true,
        errors: [],
        dirtyFields: [],
        emptyFields: [''],
        valid: false,
      },
    },
  }

  const [contactLensesData, setContactLensesData] = useState<ContactLensesData>(initialContactLensesState)

  useEffect(() => {
    setContactLensesData({
      left: {
        ...initialContactLensesState.left,
        ...defaultFieldsValues.left,
        x_productId: pdpData?.id,
        fieldsStatus: {
          ...contactLensesData.left.fieldsStatus,
          emptyFields: defaultEmptyFields.left,
        },
      },
      right: {
        ...initialContactLensesState.right,
        ...defaultFieldsValues.right,
        x_productId: pdpData?.id,
        fieldsStatus: {
          ...contactLensesData.right.fieldsStatus,
          emptyFields: defaultEmptyFields.right,
        },
      },
    })
  }, [pdpData, defaultFieldsValues, defaultEmptyFields])

  useMemo(() => {
    const configuration = !!currentProduct ? getLensConfiguration(t, currentProduct, defaultValues) : null

    const defaultValuesEyeMap = getDefaultValues(configuration)
    setContactLensConfig(configuration)
    setDefaultFieldsValues({
      left: defaultValuesEyeMap[CL_EYE_LEFT].fieldsDefaultValues,
      right: defaultValuesEyeMap[CL_EYE_RIGHT].fieldsDefaultValues,
    })
    setDefaultEmptyFields({
      left: defaultValuesEyeMap[CL_EYE_LEFT].defaultEmptyFields,
      right: defaultValuesEyeMap[CL_EYE_RIGHT].defaultEmptyFields,
    })
  }, [currentProduct, defaultValues?.left.length, defaultValues?.right.length])

  const areBothEyesDisabled = (data: ContactLensesData): boolean => {
    return (!data[CL_EYE_LEFT]?.fieldsStatus?.enabled && !data[CL_EYE_RIGHT]?.fieldsStatus?.enabled) || false
  }

  const checkIfColorIsEmpty = (): boolean => {
    return Object.values(contactLensesData)
      .map(eye => {
        return eye.fieldsStatus?.errors || []
      })
      .some(eye_side => eye_side[0] === 'x_color')
  }

  const areErrorsInFields = (): boolean => {
    return (
      Object.values(contactLensesData).filter(eye => {
        return eye?.fieldsStatus?.errors && eye?.fieldsStatus?.errors.length > 0
      }).length > 0 || false
    )
  }

  const fieldsAreNotValid = (data: ContactLensesData): boolean => {
    return (
      Object.values(data).filter(eye => {
        return eye?.fieldsStatus?.enabled && !eye?.fieldsStatus?.valid
      }).length > 0
    )
  }

  const getTotalBoxesCount = (data: ContactLensesData): number => {
    const totalBoxes = sum([
      parseInt(
        data[CL_EYE_LEFT]?.fieldsStatus?.enabled
          ? data[CL_EYE_LEFT]?.quantity || CONTACT_LENS_DEFAULT_QUANTITY
          : CONTACT_LENS_DEFAULT_QUANTITY
      ),
      parseInt(
        data[CL_EYE_RIGHT]?.fieldsStatus?.enabled
          ? data[CL_EYE_RIGHT]?.quantity || CONTACT_LENS_DEFAULT_QUANTITY
          : CONTACT_LENS_DEFAULT_QUANTITY
      ),
    ])
    return totalBoxes
  }

  useEffect(() => {
    setFieldsHaveErrors(areErrorsInFields())
    setIsColorEmpty(checkIfColorIsEmpty())
  }, [contactLensesData])

  return {
    defaultFieldsValues,
    defaultEmptyFields,
    contactLensFieldsConfig,
    setContactLensesData,
    contactLensesData,
    fieldsHaveErrors,
    areBothEyesDisabled,
    bothEyesDisabled,
    setBothEyesDisabled,
    setFieldsHaveErrors,
    fieldsAreNotValid,
    StringToNumberValuesMap,
    formatDataForCart,
    getTotalBoxesCount,
    isColorEmpty,
  }
}
