import React from 'react'
import { IPlacement, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import styled from '@mui/material/styles/styled'
import PlpTiles from '../../../CmsPlacement/PlpTiles'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { IGridPositioning } from '@typesApp/cmsPlacement/LXTeaser'
import { PlacementContextWrapper } from '@components/Cms/CmsComponents-CSS/PlacementContextWrapper/PlacementContextWrapper'
import { getColumnSpan } from '@utils/productImage'

interface IProps {
  placement: IPlacement
  productListingLayout: 'full' | 'compact'
  isFullWidthProduct?: boolean
}

const getGridColumn = (type: IViewType, gridPositioning: IGridPositioning) => {
  let columSpan

  switch (type) {
    case 'plp-one-tile':
      columSpan = 2
      break
    case 'plp-two-tiles':
      columSpan = 4
      break
    case 'plp-three-tiles':
      columSpan = 6
      break
    default:
      columSpan = 2
  }

  switch (gridPositioning) {
    case 1:
      return `1 / span ${columSpan}`
    case 2:
      return `3 / span ${columSpan}`
    case 3:
      return `5 / span ${columSpan}`
    default:
      return `1 / span ${columSpan}`
  }
}
const getGridColumnMobile = (type: IViewType | null) => `1/ span ${getColumnSpan(type, 'mobile')}`
const getGridColumnMobileFullView = (type: IViewType | null) => `1/ span ${getColumnSpan(type, 'mobileFullView')}`

const getGridRow = (verticalIndex: number) => `${verticalIndex} / span 1`

const StyledGridBannerItem = styled('div', {
  name: 'GridBanner',
  slot: 'Item',
  shouldForwardProp: propName =>
    propName !== 'viewtype' &&
    propName !== 'verticalIndex' &&
    propName !== 'gridPositioning' &&
    propName !== 'productListingLayout' &&
    propName !== 'isFullWidthProduct',
})<{
  viewtype: IViewType
  verticalIndex: number
  gridPositioning: IGridPositioning
  productListingLayout: 'full' | 'compact'
  isFullWidthProduct: boolean
}>(({ theme, viewtype, verticalIndex, gridPositioning, productListingLayout, isFullWidthProduct }) => ({
  gridColumn: getGridColumn(viewtype, gridPositioning),
  gridRow: getGridRow(verticalIndex),
  [theme.breakpoints.down('md')]: {
    gridColumn: productListingLayout === 'full' ? 'auto / span 12' : getGridColumn(viewtype, gridPositioning),
  },
  [theme.breakpoints.down('sm')]: {
    gridColumn:
      isFullWidthProduct || productListingLayout === 'full'
        ? getGridColumnMobileFullView(viewtype)
        : getGridColumnMobile(viewtype),
  },
}))

const GridBannerItem: React.FC<IProps> = props => {
  const { placement, productListingLayout, isFullWidthProduct = false } = props
  let viewtype
  let items

  if (placement.viewtype === 'default') {
    const collection = placement.items.find(isCMCollection)
    items = collection?.teasableItems
    viewtype = collection?.viewtype || ''
  } else {
    items = placement.items
    viewtype = placement.viewtype
  }

  const item = items?.find(isLXTeaser)
  if (!item) return null

  const gridPositioning = item.gridPositioning ?? 1
  const split = placement.name.split('_')
  const verticalIndex = Number(split.pop()) ?? 1

  const formattedPlacement: IPlacement = {
    ...placement,
    viewtype,
    items,
  }

  return (
    <StyledGridBannerItem
      viewtype={viewtype}
      verticalIndex={verticalIndex}
      gridPositioning={gridPositioning}
      productListingLayout={productListingLayout}
      isFullWidthProduct={isFullWidthProduct}
    >
      <PlpPlacement placement={formattedPlacement} />
    </StyledGridBannerItem>
  )
}

const PlpPlacement: React.FC<{ placement: IPlacement }> = props => {
  const { placement } = props
  const viewType = placement?.viewtype
  let DynamicComponent

  switch (viewType) {
    case 'plp-one-tile':
    case 'plp-two-tiles':
    case 'plp-three-tiles':
      DynamicComponent = PlpTiles
      break
    default:
      DynamicComponent = null
  }

  return (
    <PlacementContextWrapper initialData={{ placement }}>
      {DynamicComponent && <DynamicComponent placement={placement} />}
    </PlacementContextWrapper>
  )
}
export default GridBannerItem
