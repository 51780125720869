import React from 'react'
import styled from '@mui/material/styles/styled'
import { useSelector } from 'react-redux'
import { isPlpSeoBlockEnabled } from '@features/plp/selector'

const StyledEndPageContainer = styled('div', {
  name: 'BottomSEOBlock',
  slot: 'EndPageContainer',
})(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(4),
  padding: theme.spacing(0, 4),
  color: `${theme.palette.text.dark.primary}`,

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 16),
  },
}))

const StyledEndPageTitle = styled('h2', {
  name: 'BottomSEOBlock',
  slot: 'EndPageTitle',
})(({ theme }) => ({
  textTransform: 'uppercase',
  fontSize: 20.8,
  borderTop: `1px solid ${theme.palette.text.light.tertiary}`,
  paddingTop: theme.spacing(6),
}))

const StyledEndPageContent = styled('div', {
  name: 'BottomSEOBlock',
  slot: 'EndPageContent',
})(({ theme }) => ({
  paddingBottom: theme.spacing(6),

  fontSize: 12.8,

  '& > div p': {
    lineHeight: 1.5,
  },

  '.seoContent': {
    columns: 'auto 1',
  },
}))

const BottomSEOBlock = ({
  title,
  description,
  isEnabled,
}: {
  title: string
  description: string
  isEnabled: boolean
}) => {
  if (!isEnabled) return null

  return (
    <StyledEndPageContainer className="plp__seo-text-container">
      <StyledEndPageTitle>{title}</StyledEndPageTitle>
      <StyledEndPageContent>
        <div className="seo-content" dangerouslySetInnerHTML={{ __html: description || '' }} />
      </StyledEndPageContent>
    </StyledEndPageContainer>
  )
}

export default BottomSEOBlock
