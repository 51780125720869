import React, { useContext, type Dispatch } from 'react'
import { createContext, useReducer } from 'react'
import plpReducer, { IActionPlpType, IPlpState } from './PlpReducer'

export const PlpContext = createContext<IPlpState | null>(null)
export const PlpDispatchContext = createContext<Dispatch<IActionPlpType> | null>(null)

export function PlpProvider({ initialState, children }): JSX.Element {
  const [plpState, dispatch] = useReducer(plpReducer, initialState)

  return (
    /* locale needs to be read from the latest initialState, since we depend on the parent setting the context for it's most recent value */
    <PlpContext.Provider value={{...plpState, locale: initialState?.locale }}> 
      <PlpDispatchContext.Provider value={dispatch}>{children}</PlpDispatchContext.Provider>
    </PlpContext.Provider>
  )
}

export function usePlpState(): IPlpState {
  const plpContext = useContext(PlpContext)
  if (!plpContext) {
    throw new Error('usePlpState must be used within a PlpProvider')
  }
  return plpContext
}

export function usePlpDispatch(): Dispatch<IActionPlpType> {
  const plpDispatchContext = useContext(PlpDispatchContext)
  if (!plpDispatchContext) {
    throw new Error('usePlpDispatch must be used within a PlpProvider')
  }
  return plpDispatchContext
}
