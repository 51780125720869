import styled from '@mui/material/styles/styled'
import { teaserBackGroundColorToBg } from '@utils/placements'
import { Swiper } from 'swiper/react'

export const SwiperDiv = styled('div', {
  name: 'OmniChannel',
  slot: 'Swiper',
  shouldForwardProp: prop => prop !== 'bgColor',
})<{
  bgColor?: string
}>(({ theme, bgColor }) => ({
  ...teaserBackGroundColorToBg(bgColor, theme),
  fontSize: '14px',
  color: theme.palette.text.dark.primary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  gap: '1rem',
  minHeight: 62,
  svg: {
    height: `${theme.spacing(6)}px`,
    width: `${theme.spacing(6)}px`,
    marginRight: `${theme.spacing(4)}px`,
  },
}))

export const TooltipWrapper = styled('div', {
  name: 'OmniChannel',
  slot: 'Tooltip',
})(() => ({
  display: 'flex',
}))

export const StyledSwiper = styled(Swiper, {
  name: 'OmniChannel',
  slot: 'SwiperWrapper',
})(() => ({
  textAlign: 'center',
  width: '100%',
}))

export const OmniChannelCarouselWrapper = styled('div', {
  name: 'OmniChannel',
  slot: 'CarouselWrapper',
})(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '.swiper-button-prev, .swiper-button-next': {
    color: theme.palette.text.dark.primary,
    width: 0,
  },
  '.swiper-button-prev::after, .swiper-button-next::after': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}))
