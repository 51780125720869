import React from 'react'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { TeaserOverlayStyle, TOverlayTextAlign } from '../../../types/teaser'
import {
  ContentPlpTiles,
  ContentTextPlpTiles,
  CtaPlpTiles,
  ImagePlpTiles,
  TextPlpTiles,
  // TODO: optional pretitle
  // PreTitlePlpTiles,
  TitlePlpTiles,
  WrapperPlpTiles,
  OverlapTextModuleContainer,
  BelowImageTextModuleContainer,
} from './PlpTiles.style'

import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { ShoppableTeaser } from '@components/Cms/CmsComponents-CSS/ShoppableTeaser'
import { Link } from '@components/common/Link/Link'
import { TextModuleTeaser } from '../../CmsModules/TextModule/components/TextModuleTeaser'
import useMediaByDeviceType from '../../../hooks/useMediaByDeviceType'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useTheme } from '@mui/material'
import HTMLReactParser from 'html-react-parser'

const controlTypeSize = (viewtype: string) => {
  switch (viewtype) {
    case 'plp-one-tile':
      return 'PLP_ONE_TILE'
    case 'plp-two-tiles':
      return 'PLP_TWO_TILES'
    case 'plp-three-tiles':
      return 'PLP_THREE_TILES'
    default:
      return ''
  }
}

const PlpTiles: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const { isDesktop, isTabletWidthAbove768 } = useBreakpoints()
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const teaserItem = placement.items?.find(isLXTeaser)
  const media = useMediaByDeviceType(teaserItem?.media!)

  if (!teaserItem) return null
  const typeSize = controlTypeSize(viewType)

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const isUseTextModule = viewType === 'plp-three-tiles'
  const isSingleTile = viewType === 'plp-one-tile'
  const isOverlapText = isUseTextModule && (isTabletWidthAbove768 || isDesktop)
  const showGradient = teaserItem?.hotZonesSettings?.lightContrast && isOverlapText
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings?.[0]
  const textAlign = teaserItem.teaserOverlay1TextAlign || teaserItem.teaserOverlay2TextAlign
  const teaserOverlayStyle = teaserItem.teaserOverlay1Style || teaserItem.teaserOverlay2Style || 'text-dark-primary'

  const TileImage = () => (
    <>
      <CMSCommonMedia type={typeSize} media={media} />
      <OverlapContent
        teaserItem={teaserItem}
        viewType={placement?.viewtype}
        showText={isOverlapText}
        teaserOverlayStyle={teaserOverlayStyle}
      />
    </>
  )

  return (
    <WrapperPlpTiles bgcolor={teaserItem.teaserBackground} {...metaData}>
      <ImagePlpTiles showGradient={showGradient}>
        {teaserLXCallToActionSettings?.callToActionEnabled ? (
          <Link
            href={teaserLXCallToActionSettings?.target?.formattedUrl ?? '/'}
            data-element-id={`X_X_PLP_Tiles_${viewType}_Placement_Banner_${teaserLXCallToActionSettings.callToActionText?.replaceAll(
              ' ',
              '_'
            )}_CTA0`}
          >
            <TileImage />
          </Link>
        ) : (
          <TileImage />
        )}
      </ImagePlpTiles>

      <ContentPlpTiles showInline={!isSingleTile}>
        <BelowImageContent
          teaserItem={teaserItem}
          viewType={placement?.viewtype}
          isUseTextModule={isUseTextModule}
          teaserOverlayStyle={teaserOverlayStyle}
          textAlign={textAlign}
        />
      </ContentPlpTiles>
    </WrapperPlpTiles>
  )
}

export interface OverlapContentProps {
  teaserItem: ILXTeaser
  viewType: IViewType
  showText: boolean
  teaserOverlayStyle: any
}

const OverlapContent: React.FC<OverlapContentProps> = ({ teaserItem, viewType, showText, teaserOverlayStyle }) => {
  const shoppable = (teaserItem?.hotZones?.length && teaserItem?.hotZones?.length >= 0) || false
  const lightContrast = teaserItem?.hotZonesSettings?.lightContrast
  const ctaStyle = !lightContrast ? 'cta-fill-primary-light' : 'cta-fill-primary-dark'
  const theme = useTheme()
  return (
    <>
      {shoppable && <ShoppableTeaser />}

      {showText && (
        <OverlapTextModuleContainer>
          <TextModuleTeaser
            item={teaserItem}
            viewType={viewType || 'default'}
            teaserIndex={0}
            options={{
              iconColor: !lightContrast ? theme.palette.text.dark.primary : theme.palette.text.light.primary,
              overlayStyle: teaserOverlayStyle ? teaserOverlayStyle : !lightContrast ? 'text-dark-primary' : undefined,
              ctaStyle: ctaStyle,
              ctaAutoWidth: true,
            }}
          />
        </OverlapTextModuleContainer>
      )}
    </>
  )
}

export interface BelowImageContentProps {
  teaserItem: ILXTeaser
  viewType: IViewType
  isUseTextModule: boolean
  teaserOverlayStyle: TeaserOverlayStyle
  textAlign: TOverlayTextAlign
}

const BelowImageContent: React.FC<BelowImageContentProps> = ({
  teaserItem,
  viewType,
  isUseTextModule,
  teaserOverlayStyle,
  textAlign,
}) => {
  const { isMobile, isTabletWidthBelowOrEqualTo768 } = useBreakpoints()

  const appliedText = teaserItem?.teaserText1 || teaserItem?.teaserText2 || ''
  const title =
    teaserItem?.teaserTitle1 || teaserItem?.teaserTitle2 || teaserItem?.teaserTitle3 || teaserItem?.teaserTitle4 || ''

  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings || []
  const isTextBelowImage = isUseTextModule && (isMobile || isTabletWidthBelowOrEqualTo768)
  const theme = useTheme()

  return (
    <>
      {!isUseTextModule && (
        <>
          <ContentTextPlpTiles>
            <TitlePlpTiles overlayTxtAlign={textAlign} teaserOverlayStyle={teaserOverlayStyle}>
              {title}
            </TitlePlpTiles>
            <TextPlpTiles overlayTxtAlign={textAlign} teaserOverlayStyle={teaserOverlayStyle}>
              {HTMLReactParser(appliedText)}
            </TextPlpTiles>
          </ContentTextPlpTiles>

          {teaserLXCallToActionSettings.length > 0 && (
            <CtaPlpTiles
              data-element-id={`X_X_PLP_Tiles_${viewType}_Placement_Banner_${teaserLXCallToActionSettings[0].callToActionText?.replaceAll(
                ' ',
                '_'
              )}_CTA0`}
            >
              {teaserLXCallToActionSettings.map((actionSettings, index) => (
                <CmsCta
                  key={`cms-content__${viewType}--cta-${index}`}
                  dataElementId={`X_X_PLP_Tiles_${viewType}_Placement_Banner_${actionSettings.callToActionText?.replaceAll(
                    ' ',
                    '_'
                  )}_CTA${index}`}
                  teaserCtaSetting={actionSettings}
                />
              ))}
            </CtaPlpTiles>
          )}
        </>
      )}

      {isTextBelowImage && (
        <BelowImageTextModuleContainer>
          <TextModuleTeaser
            item={teaserItem}
            viewType={viewType || 'default'}
            teaserIndex={0}
            options={{
              iconColor: theme.palette.text.primary,
              overlayStyle: teaserOverlayStyle,
              ctaAutoWidth: true,
            }}
          />
        </BelowImageTextModuleContainer>
      )}
    </>
  )
}

export default PlpTiles
