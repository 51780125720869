// import { useDispatch, useSelector } from 'react-redux'
import { getDynamicCmsContentPlpAlgolia } from '@foundation/hooks/useDynamicCmsContent'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { Refinement } from '@utils/Plp/PlpReducer'

const usePlpPlacements = ({ plpCommerce }, appliedFacets = [] as Refinement[]) => {
  const customerSegments = useCustomerSegmentsUtil()
  const placementsPlpCommerce =
    getDynamicCmsContentPlpAlgolia(plpCommerce?.commercePlacements ?? [], appliedFacets, customerSegments) ?? []

  const plpDescription = plpCommerce?.PLPDescription || {
    numFound: 0,
    result: [],
  }

  return {
    placementsPlpCommerce,
    plpDescription,
  }
}

export default usePlpPlacements
