import React, { useState } from 'react'
import { ContentFaqs, StyledAccordion, StyledAccordionSummary, WrapperFaqs } from './Faqs.style'
import { replaceTextFromXStore } from '../../../../../utils/placements'
import useCmsTeaserBanner from '../../../../../hooks/useCmsTeaserBanner'
import { IPlacement, isCMArticle, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { useSite } from '@foundation/hooks/useSite'
import { CmsTeaserDetailText } from '@components/Cms/CmsComponents-CSS/CmsTeaserDetailText/CmsTeaserDetailText'
import { CmsTeaserTitle } from '@components/Cms/CmsComponents-CSS/CmsTeaserTitle/CmsTeaserTitle'
import styles from './styles/Faqs.module.scss'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const Faqs: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const articles = placement?.items?.filter(isCMArticle) || []
  const teaser = placement?.items?.find(isLXTeaser)
  const viewType = placement?.viewtype || 'default'
  const { teaserTitleValue, teaserTitle, promoteToH1 } = useCmsTeaserBanner({ item: teaser, viewType }) || {}

  return (
    <WrapperFaqs>
      <CmsTeaserTitle
        className={styles.titleContainer}
        dataCmMetadata={`[{"_":"properties.${teaserTitle}"}]`}
        variant={promoteToH1 ? 'h1' : 'h2'}
      >
        {teaserTitleValue}
      </CmsTeaserTitle>
      <ContentFaqs>
        {articles.map(article => (
          <AccordionArticle article={article} key={article.id} />
        ))}
      </ContentFaqs>
    </WrapperFaqs>
  )
}

export const AccordionArticle: React.FC<{ article: ICMArticle }> = ({ article }) => {
  const [expanded, setExpanded] = useState<string | false>(false)
  const { mySite } = useSite()

  const detailText = replaceTextFromXStore(article.detailText || '', mySite.xStoreCfg.shortCodes)
  const handleChange = (id: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : false)
  }

  return (
    <StyledAccordion expanded={expanded === article.id} onChange={handleChange(article?.id!)}>
      <StyledAccordionSummary
        expandIcon={
          expanded ? (
            <SVGIcon library="misc3" name="remove-circle" size={32} />
          ) : (
            <SVGIcon library="misc3" name="add-circle" size={32} />
          )
        }
      >
        {article.title}
      </StyledAccordionSummary>

      {detailText && <CmsTeaserDetailText className={styles.textContainer} teaserTextValue={detailText} />}
    </StyledAccordion>
  )
}

export default Faqs
