import React, { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import { ProductRightColumnProps } from '@typesApp/product'
import { ProductNameSection } from '../ProductNameSection'
import ProductCtaContainer from '../ProductCtaContainer'
import ProductAvailableNotificationDrawer from '../ProductAvailableNotificationDrawer'
import { ProductDetailsList } from '../ProductDetailsList'
import { SpecialFrameInfo } from '../SpecialFrameInfo'
import { RightColumnErrorContainer, StyledSeparator } from '../../ProductDetails.style'
import SizeSwitcher from '../SizeSwitcher'
import { getClVolume } from '../../../../utils/productAttributes'
import { generateQuantityOptions } from '../../../../utils/order'
import { ShippingTimesBanner } from '../ShippingTimesBanner/ShippingTimesBanner'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '../../../../constants/product'
import { getProductAlgoliaPrice } from '../../../../foundation/algolia/algoliaPrice'
import { useTranslation } from 'next-i18next'
import { useSelector } from 'react-redux'
import { addSubscriptionToCartErrorSelector } from '@features/product/selector'
import { SubscriptionSelectionOption } from '@views/Subscription/pdp/SubscriptionPDP'
import { toNumber } from 'lodash-es'
import { TrustPilotMiniWidget } from '@components/TrustPilotReviews/TrustPilotWidget'
import useBreakpoints from '@hooks/useBreakpoints'
import { ContactLensesSelect } from '../ContactLensesSelect'
import { CONTACT_LENS_ACCESSORIES_DEFAULT_QUANTITY } from '@constants/common'
import { MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY } from '@constants/order'
import ErrorMessage from '@components/UI-CSS/ErrorMessage'
import styles from './styles/PdpRight.module.scss'
import { sendPdpEvent } from '@foundation/analytics/tealium/lib'
import productUtils from '@utils/ProductUtils'
import { localeLangCountryUtil } from '@utils/countryUtil'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { getProductsPartNumbers } from '@utils/product'
import { initIndexName } from '@foundation/algolia/algoliaConfig'
import { useGetRecommendedProducts } from '@components/AlgoliaRecommendations/AlgoliaRecommendations'
import { OmniChannelCarousel } from '../OmniChannelCarousel/OmnichannelCarousel'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

const ASSOCIATION_TYPE = 'CL_Pack_Sizes'

export const CLAccessoriesPdpRightColumn = (props: ProductRightColumnProps) => {
  const {
    isLoading,
    partNumber,
    currentProduct,
    soldOutStatus,
    productInCart,
    addToCartButtonFail,
    ctaRef,
    cluster,
    isSoldout,
    isRoxable,
    productQuantity,
    setProductQuantity,
    adjustments,
    setAdjustments,
    analyticsPdpData,
    availableQuantity,
    onAddToCartClick,
    liveStockError,
    placements,
  } = props
  const { t: translate } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const maxQuantity = availableQuantity ? availableQuantity : MAX_PURCHASABLE_QUANTITY_CL_ACCESSORY
  const quantityOptions = generateQuantityOptions(maxQuantity + 1, 1, availableQuantity)
  const customerSegment = useCustomerSegmentsUtil()
  const handleQuantityUpdate = value => {
    setProductQuantity([value])
  }
  const displayable = currentProduct.displayable
  const isDiscontinued = productUtils.isProductDiscontinued(currentProduct)

  const addSubscriptionToCartError = useSelector(addSubscriptionToCartErrorSelector)

  const clusterWithMerchandisingAssociations = cluster?.find(c => !!c.merchandisingAssociations)
  const getAssociatedMerchandising = cp => {
    return cp?.merchandisingAssociations?.some(ma => ma?.associationType === ASSOCIATION_TYPE)
  }

  const hasAssociatedMerchandising = useMemo(
    () => getAssociatedMerchandising(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const getSizeSwitcherOptions = cp => {
    return cp?.merchandisingAssociations
      ?.filter(ma => ma.associationType === ASSOCIATION_TYPE)
      .map(ma => {
        const product = ma?.cluster?.[0]
        const size = getClVolume(product)
        return { size, label: '', href: product?.seo?.href }
      })
  }

  const options = useMemo(
    () => getSizeSwitcherOptions(clusterWithMerchandisingAssociations),
    [clusterWithMerchandisingAssociations]
  )

  const currentSize = (currentProduct && getClVolume(currentProduct)) || ''

  const algoliaPrices = getProductAlgoliaPrice(customerSegment, currentProduct)

  const quantitySelectLabel = translate('ContactLenses.Labels.Quantity').toString().toUpperCase()
  const currentQuantity = Number(productQuantity?.[0] ?? CONTACT_LENS_ACCESSORIES_DEFAULT_QUANTITY)
  const quantityValue = currentQuantity <= maxQuantity ? currentQuantity : maxQuantity

  const QuantitySelect = (
    <ContactLensesSelect
      id={'quantity'}
      eye={'default'}
      value={quantityValue.toString()}
      onSelectValueChange={(_eye, _id, val) => {
        handleQuantityUpdate(val)
      }}
      key={'cl-acc-quantity'}
      options={quantityOptions}
    />
  )

  const isOutOfStockCLAccessory = isSoldout || soldOutStatus === PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK

  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)
  const productsPartNumber: string[] = getProductsPartNumbers([currentProduct])
  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const recommendationProducts = useGetRecommendedProducts('', indexName, productsPartNumber, '').recommendations
  const hasRecommendedProducts = !!recommendationProducts?.length

  useEffect(() => {
    if (liveStockError && analyticsPdpData) {
      sendPdpEvent({
        ...analyticsPdpData,
        soldOutStatus: PRODUCT_SOLDOUT_STATUS.OUT_OF_STOCK,
        common: {
          ...analyticsPdpData.common,
          Error_Details: currentProduct.items?.[0]?.partNumber || '',
          Error_Message: translate('OutOfStock.SorrySoldOut') + ' ' + translate('OutOfStock.BackInStock'),
          Error_Source: 'Product',
          Events_Error: '1',
          Error_Code: 'Out of stock',
        },
      })
    }
  }, [liveStockError])

  return (
    <>
      {currentProduct && <ProductNameSection currentProduct={currentProduct} isSoldout={isSoldout} />}
      {isDesktop && currentProduct && <TrustPilotMiniWidget sku={partNumber} />}
      {hasAssociatedMerchandising && <SizeSwitcher options={options} currentSize={currentSize} />}
      {currentProduct && !isSoldout ? (
        <>
          <SpecialFrameInfo currentProduct={currentProduct} />
          <StyledSeparator />
        </>
      ) : null}

      <div
        className={clsx(styles.clAccessoriesContainer, {
          [styles.soldOut]: isOutOfStockCLAccessory,
        })}
      >
        {currentProduct && displayable && (
          <SubscriptionSelectionOption
            currentProduct={currentProduct}
            productQuantity={toNumber(productQuantity?.[0] ?? 1)}
            isLoading={isLoading}
            soldOutStatus={soldOutStatus}
            adjustments={adjustments}
            setAdjustments={setAdjustments}
          >
            <>
              <div className={clsx(styles.quantityWrapper)}>
                <div className={clsx(styles.quantityLabel)}>{quantitySelectLabel}</div>
                {QuantitySelect}
              </div>
            </>
          </SubscriptionSelectionOption>
        )}
        {displayable && <div className={clsx(styles.styledSeparator)} />}

        {currentProduct && (
          <>
            {!isDiscontinued && isOutOfStockCLAccessory && !liveStockError && (
              <ErrorMessage
                errorTitle={translate('OutOfStock.SorrySoldOut')}
                errorDescription={translate('OutOfStock.BackInStock')}
              />
            )}

            {isDiscontinued && isOutOfStockCLAccessory && !liveStockError && (
              <ErrorMessage
                errorTitle={translate('OutOfStock.SorryDiscontinued')}
                errorDescription={translate(
                  hasRecommendedProducts ? 'OutOfStock.BrowseSimilarProducts' : 'Discontinued.NoRecommendationMsg'
                )}
              />
            )}

            <ProductCtaContainer
              productInCart={productInCart}
              isLoading={isLoading}
              soldOutStatus={soldOutStatus}
              currentProduct={currentProduct}
              error={addToCartButtonFail}
              ctaRef={ctaRef}
              roxable={isRoxable}
              addToCartFillType={'fill'}
              productQuantity={productQuantity}
              algoliaPrices={algoliaPrices}
              onAddToCartClick={onAddToCartClick}
            />
          </>
        )}
      </div>

      <RightColumnErrorContainer>
        {addSubscriptionToCartError && translate('Subscriptions.Errors.PendingSubscriptionInCart')}
      </RightColumnErrorContainer>
      {!isOutOfStockCLAccessory && <ShippingTimesBanner productType={PRODUCT_TYPES_KEYS.CONTACT_LENSES_ACCESSORIES} />}
      <ProductAvailableNotificationDrawer
        attachments={currentProduct?.attachments || []}
        attributes={currentProduct?.attributes || []}
        product={currentProduct || undefined}
        soldOutStatus={soldOutStatus}
      />

      {liveStockError && (
        <ErrorMessage
          className={styles.clAccessoriesErrorContainer}
          errorTitle={translate('OutOfStock.SorrySoldOut')}
          errorDescription={translate('OutOfStock.BackInStock')}
        />
      )}

      <OmniChannelCarousel placements={placements ?? []} />

      {currentProduct && (
        <ProductDetailsList type={soldOutStatus} isLoading={isLoading} currentProduct={currentProduct} />
      )}
    </>
  )
}
