import { ProductColorSelectorItem, ProductColorSelectorItemImage } from './ProductColorItem.style'

import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import React from 'react'
import { IProduct } from '../../../types/product'
import { getSoldOut } from '../../../utils/productAttributes'
import theme from '../../../themes'
import { PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE } from '@components/ProductImage/ProductImage'
import { getProductImageAltLabel } from '@utils/productImage'

export interface IProductColorItem {
  product: IProduct // cluster item
}

/**
 * @description Item inside a ProductColorSelector from PDP.
 *
 * @param {IProduct} product is a cluster item
 */
const ProductColorItem: React.FC<IProductColorItem> = ({
  product, // cluster item
}) => {
  const soldOutStatus = getSoldOut(product)
  const isSoldOut = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT
  //const imageSequence = '3.0' // TODO get this from xstoreconf as for main PDP images

  return (
    <ProductColorSelectorItem isSoldOut={isSoldOut}>
      <ProductColorSelectorItemImage
        alt={getProductImageAltLabel(product)}
        noImageStyle={{
          transform: 'translateX(0)',
          height: '90%',
        }}
        width={160}
        attachments={product.attachments}
        usage="PLP"
        sequence={PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE}
        backgroundColor={theme.palette.background.light.primary}
        lazy={false}
        preload={false}
      />
    </ProductColorSelectorItem>
  )
}

export default ProductColorItem
