import React from 'react'
import { Navigation, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { v4 as uuid } from 'uuid'

import { OmniChannelLink } from './components/OmniChannelLink'
import { StyledTooltip } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'

import { OmniChannelCarouselWrapper, StyledSwiper, SwiperDiv, TooltipWrapper } from './OmnichannelCarousel.style'

interface OmniChannelCarouselProps {
  placements: IPlacement<IPlacementItem>[]
}

export const OmniChannelCarousel = (props: OmniChannelCarouselProps) => {
  const { placements } = props
  const omnichannelData =
    placements?.find(x => x.name === 'PDP_omnichannel_carousel') ?? ({} as IPlacement<IPlacementItem>)
  const omnichannelItems = omnichannelData?.items as ILXTeaser[]

  const showCarousel = omnichannelItems?.some(item => {
    const { teaserTitle4, teaserText1, teaserText2 } = item as ILXTeaser
    return teaserTitle4 || teaserText1 || teaserText2
  })

  if (!showCarousel) {
    return null
  }

  return omnichannelItems?.length > 1 ? (
    <OmniChannelCarouselWrapper>
      <StyledSwiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Pagination, Navigation]}
      >
        {omnichannelItems?.map((i, index) => (
          <SwiperSlide key={`dc-info__${uuid()}`}>
            <SwiperDiv bgColor={omnichannelData?.backgroundColor}>
              <OmniChannelLink
                actionSettings={i.teaserLXCallToActionSettings[0]}
                dataElementId={`X_X_1Placement_OmniChannel${index}_CTA`}
                icon={i.teaserIcon}
                title={i.teaserTitle4}
                text={i.teaserText1}
              />
              {i.teaserText2 && (
                <StyledTooltip
                  contentVariant={{
                    type: 'textonly',
                    body: i.teaserText2.replace(/<.*?>/gi, ''),
                  }}
                  colorVariant={'dark'}
                >
                  <TooltipWrapper>
                    <SVGIcon library="validation" name="info" />
                  </TooltipWrapper>
                </StyledTooltip>
              )}
            </SwiperDiv>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </OmniChannelCarouselWrapper>
  ) : (
    <OmniChannelCarouselWrapper>
      {omnichannelItems?.map((i, index) => (
        <SwiperDiv key={uuid()} bgColor={omnichannelData?.backgroundColor}>
          <OmniChannelLink
            actionSettings={i.teaserLXCallToActionSettings[0]}
            dataElementId={`X_X_1Placement_OmniChannel${index}_CTA`}
            icon={i.teaserIcon}
            title={i.teaserTitle4}
            text={i.teaserText1}
          />
          {i.teaserText2 && (
            <StyledTooltip
              contentVariant={{
                type: 'textonly',
                body: i.teaserText2.replace(/<.*?>/gi, ''),
              }}
              colorVariant={'dark'}
            >
              <TooltipWrapper>
                <SVGIcon library="validation" name="info" />
              </TooltipWrapper>
            </StyledTooltip>
          )}
        </SwiperDiv>
      ))}
    </OmniChannelCarouselWrapper>
  )
}
